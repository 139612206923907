import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
// @ts-ignore
import { feedbackByVacancy } from '@/api/jobs/aiAssistants'
import Vacancy from '@/models-ts/vacancies/Vacancy'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, snackMixin],
  props: {
    vacancy: Vacancy,
    isModal: Boolean,
  },
  data () {
    return {
      comment: '',
      score: 1,
      sending: false
    }
  },
  methods: {
    async onSendFeedback () {
      this.sending = true
      try {
        await feedbackByVacancy(this.vacancy.id, this.score, this.comment)
        this.$emit('success')
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error sending feedback. Please try again.',
        })
      } finally {
        this.sending = false
      }
    },
    onScoreChange (value: any) {
      if (this.sending) return
      this.score = value
    }
  }
})
