import Vue from 'vue'
import VacancyRateTalent from '@/partials/VacancyRateTalent/VacancyRateTalent.vue'
import Vacancy from '@/models-ts/vacancies/Vacancy'

export default Vue.extend<any, any, any, any>({
  name: 'lx-rate-talent',
  components: {
    VacancyRateTalent
  },
  props: {
    vacancy: Vacancy,
  },
  methods: {
    onSuccessFeedBackSent () {
      this.$emit('success')
      this.$parent.$emit('success')
    }
  },
})
