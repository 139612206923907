import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { RootState } from '@/store'
import { RoleOption } from '@/store/shared/modules/vacancyRoles/types'

export default Vue.extend<any, any, any, any>({
  props: {
    value: Number
  },
  data () {
    return {
      open: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      predefinedRoles: (state: RootState) => state.vacancyRoles.roles.value || [],
    }),
    ...mapGetters({
      roleOptions: 'vacancyRoles/roleOptions',
    }),
    isActive () {
      return Boolean(this.value)
    },
  },
  async mounted () {
    await this.getRoles()
  },
  methods: {
    ...mapActions({
      getRoles: 'vacancyRoles/getRoles',
    }),
    onHide () {
      this.open = false
    },
    onSelect (role: RoleOption) {
      if (!role.isCategory) {
        this.onHide()
        this.$emit('input', role.id)
      }
    },
  }
})
