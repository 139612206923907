import Vue from 'vue'
import Skill from '@/models-ts/Skill'
import navigationSkills from '@/mixins/navigationSkills'

export default Vue.extend<any, any, any, any>({
  name: 'lx-navigation-by-skills-modal',
  mixins: [navigationSkills],
  data () {
    return {
      selectedCategory: null
    }
  },
  computed: {
    categoriesMap () {
      return this.sortedCategories.filter(this.hasContent).map((category: Skill) => {
        let subSkills = this.getChilds(category.id).filter(this.hasContent)
        return { category, subSkills: subSkills.slice(0, 3), total: subSkills.length }
      })
    },
    subSkills () {
      return this.selectedCategory ? this.getChilds(this.selectedCategory.id).filter(this.hasContent) : []
    },
  },
  methods: {
    onClick () {
      this.$emit('close')
    },
  },
})
