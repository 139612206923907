import { RootState } from '@/store'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend<any, any, any, any>({
  computed: {
    ...mapState<RootState>({
      predefinedSkills: (state: RootState) => state.skills.skills.value,
      deprecatedSkills: (state: RootState) => state.skills.deprecatedSkills,
      skillsLoading: (state: RootState) => state.skills.skills.isLoading,
      skill: (state: RootState) => state.browseFreelancers.skill,
    }),
    ...mapGetters({
      categories: 'skills/getCategories',
    }),
    skillDetails () {
      const skill = this.$route.params?.skill
      if (skill) {
        return [...this.predefinedSkills, ...this.deprecatedSkills].find((s: any) => s.url === skill)
      }
      return null
    },
  },
})
