import Vue from 'vue'
// @ts-ignore
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import cloneDeep from 'lodash/cloneDeep'
// @ts-ignore
import { mixin as clickaway } from 'vue-clickaway2'
import { formatUsd } from '@/utils/moneyFormat'
import Skill from '@/models-ts/Skill'
import skillMixins from '../mixins/skillMixins'
import {
  clearPriceInputOpened,
  MAX_BUDGET,
  MIN_BUDGET,
} from '../FilterCard/FilterCard'
import { mapGetters } from 'vuex'
import { FREELANCERS_LIST, FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  name: 'lx-freelancers-filter-modal',
  mixins: [clickaway, skillMixins],
  data: () => ({
    MAX_BUDGET,
    MIN_BUDGET,
    skillFilterOpened: false,
    processing: false,
    skillsMenuOpened: false,
    selectedCategories: [],
    temporarySkills: [],
    priceInputOpened: clearPriceInputOpened(),
    budget: [0, MAX_BUDGET],
    review: 0,
  }),
  computed: {
    ...mapGetters({
      categories: 'skills/getCategories',
    }),
    search () {
      return this.$route.query.search
    },
    sort () {
      return this.$route.query.sort
    },
    hasFilter () {
      return this.search ||
        this.review > 0 ||
        this.sort ||
        this.selectedCategories.length > 0 ||
        this.budget[0] > MIN_BUDGET ||
        this.budget[1] < MAX_BUDGET
    },
  },
  mounted () {
    this.initFiltersFromQuery()
  },
  methods: {
    formatUsd,
    initFiltersFromQuery () {
      const {
        budgetFrom = MIN_BUDGET,
        budgetTo = MAX_BUDGET,
        review,
        skill = []
      } = this.$route.query
      this.review = Number(review) || 0
      this.budget = [Number(budgetFrom), Number(budgetTo)]
      this.selectedCategories = []
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          this.selectedCategories.push(mainSkill.id)
        }
      }
      this.selectedCategories = this.selectedCategories.concat((Array.isArray(skill) ? skill : [skill])
        .map((s: string) => +s)
        .filter(s => this.predefinedSkills.find((opt: any) => +opt.id === s))
        .filter(Boolean))
      this.selectedCategories = [...new Set(this.selectedCategories)]
      this.temporarySkills = [...this.selectedCategories]
    },
    // Skills filter
    onClickToSkillTrigger () {
      this.skillFilterOpened = !this.skillFilterOpened
      if (!this.skillFilterOpened) {
        this.temporarySkills = [...this.selectedCategories]
      }
    },
    onClickAwaySkill () {
      if (this.skillFilterOpened) {
        this.skillFilterOpened = false
        this.temporarySkills = [...this.selectedCategories]
      }
    },
    isSkillSelected (skill: Skill) {
      return !!this.temporarySkills.find((s: number) => s === skill.id)
    },
    onInputSkill (skill: Skill) {
      const index = this.temporarySkills.findIndex((s: number) => s === skill.id)
      if (index === -1) {
        this.temporarySkills.push(skill.id)
      } else {
        this.temporarySkills.splice(index, 1)
      }
    },
    onCategoriesClear () {
      this.temporarySkills = []
    },
    onCategoriesApply () {
      this.selectedCategories = [...this.temporarySkills]
      this.skillFilterOpened = false
      this.mapToQuery()
    },
    // Price filter
    onPriceInputClick (item: string) {
      this.priceInputOpened = {
        ...clearPriceInputOpened(),
        [item]: true,
      }
      this.$nextTick(() => {
        if (this.$refs[item]?.$el) {
          this.$refs[item].$el.querySelector('input')?.focus()
        }
      })
    },
    onClickAway (item: string) {
      if (this.priceInputOpened[item]) {
        this.priceInputOpened = {
          ...clearPriceInputOpened()
        }
      }
    },
    handleInputPrice (item: string, value: number) {
      let [from, to] = this.budget
      if (item === 'from') {
        from = value
      } else {
        to = value
      }
      if (from > MAX_BUDGET) {
        from = to = MAX_BUDGET
      }
      if (to > MAX_BUDGET) {
        to = MAX_BUDGET
      }
      if (from > to) {
        to = (from + 2) > MAX_BUDGET ? MAX_BUDGET : (from + 2)
      }
      this.budget = [from, to]
      this.debouncedMTQ()
    },
    onSliderChangeBudget (budget: Array<number>) {
      this.budget = budget
      this.debouncedMTQ()
    },
    debouncedMTQ: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    onInputReview (review: string | number) {
      this.review = review
      this.mapToQuery()
    },
    onClickReset () {
      this.review = 0
      this.budget = [MIN_BUDGET, MAX_BUDGET]
      this.temporarySkills = []
      this.selectedCategories = []
      this.mapToQuery(true)
      this.$emit('close')
    },
    mapToQuery (reset?: boolean) {
      let filters = {}
      if (!reset) {
        filters = cloneDeep(pickBy({
          ...this.$route.query,
          budgetFrom: this.budget[0],
          budgetTo: this.budget[1],
          skill: this.selectedCategories,
          review: this.review,
        }, Boolean))
      }
      let routeName = FREELANCERS_LIST
      let routeParams = cloneDeep(this.$route.params)
      if (this.$route.name === FREELANCERS_LIST_BY_SKILL) {
        if (this.selectedCategories.length) {
          routeName = FREELANCERS_LIST_BY_SKILL
        } else {
          routeParams = {}
        }
      }
      this.$router.replace({
        name: routeName,
        params: routeParams,
        query: filters,
      }).catch(() => {})
    },
  }
})
