import Vue from 'vue'
import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import cloneDeep from 'lodash/cloneDeep'
// @ts-ignore
import { mixin as clickaway } from 'vue-clickaway2'
import { formatUsd } from '@/utils/moneyFormat'
import Skill from '@/models-ts/Skill'
import skillMixins from '../mixins/skillMixins'
import { FREELANCERS_LIST, FREELANCERS_LIST_BY_SKILL } from '@/constants/routes'

export const MAX_BUDGET = 15000
export const MIN_BUDGET = 0

export const SORT_OPTIONS = [
  {
    name: 'Date',
    value: 'date',
    direction: 'desc',
    queryValue: 'date-desc',
  },
  {
    name: 'Date',
    value: 'date',
    direction: 'asc',
    queryValue: 'date-asc',
  },
  {
    name: 'Review',
    value: 'review',
    direction: 'desc',
    queryValue: 'review-desc',
  },
  {
    name: 'Review',
    value: 'review',
    direction: 'asc',
    queryValue: 'review-asc',
  },
  {
    name: 'Reputation',
    value: 'reputation',
    direction: 'desc',
    queryValue: 'reputation-desc',
  },
  {
    name: 'Reputation',
    value: 'reputation',
    direction: 'asc',
    queryValue: 'reputation-asc',
  },
]

export function clearPriceInputOpened () {
  return {
    from: false,
    to: false,
  }
}

export default Vue.extend<any, any, any, any>({
  mixins: [clickaway, skillMixins],
  data () {
    return {
      MIN_BUDGET,
      MAX_BUDGET,
      SORT_OPTIONS,
      totalLoading: false,
      processing: false,
      search: '',
      sortValue: null,
      budget: [0, MAX_BUDGET],
      review: 0,
      menuOpened: {
        sort: false,
        skill: false,
        price: false,
      },
      selectedCategories: [],
      tempCategories: [],
      priceInputOpened: clearPriceInputOpened(),
    }
  },
  computed: {
    categoriesOptions () {
      return this.categories
    },
    tags () {
      const skillTags = this.selectedCategories.map((id: number) => {
        const skill = this.predefinedSkills.find((skill: Skill) => skill.id === id)
        if (skill) {
          return {
            text: skill.name,
            type: 'skill',
            content: skill,
          }
        }
      }).filter(Boolean)
      const sortTags = this.sortValue?.name && this.sortValue?.queryValue !== 'reputation-desc'
        ? [{
          text: `Sort by: ${this.sortValue?.name}`,
          type: 'sort',
        }]
        : []

      const bFrom = !Number.isNaN(this.budget[0]) && this.budget[0] > MIN_BUDGET
      const bTo = !Number.isNaN(this.budget[1]) && this.budget[1] > MIN_BUDGET && this.budget[1] !== MAX_BUDGET
      const priceTags = bFrom || bTo
        ? [{
          text: `Budget: $${formatUsd(this.budget[0])} to $${formatUsd(this.budget[1])}`,
          type: 'price',
        }]
        : []
      return [...skillTags, ...sortTags, ...priceTags]
    },
    hasFilter () {
      return this.search ||
        this.review > 0 ||
        (this.sortValue?.value && this.sortValue?.queryValue !== 'reputation-desc') ||
        this.selectedCategories.length > 0 ||
        this.budget[0] > MIN_BUDGET ||
        this.budget[1] < MAX_BUDGET
    },
  },
  mounted () {
    this.initFiltersFromQuery()
  },
  watch: {
    async $route (newR, oldR) {
      if (newR.query !== oldR.query) {
        this.initFiltersFromQuery()
      }
    },
  },
  methods: {
    formatUsd,
    initFiltersFromQuery () {
      const {
        search = '',
        sort = null,
        budgetFrom = MIN_BUDGET,
        budgetTo = MAX_BUDGET,
        skill = [],
        review,
      } = this.$route.query
      this.review = Number(review) || 0
      this.search = search
      this.sortValue = sort ? SORT_OPTIONS.find(s => s.queryValue === sort) : SORT_OPTIONS[4]
      this.budget = [Number(budgetFrom), Number(budgetTo)]
      this.selectedCategories = []
      if (this.$route.params.skill) {
        const mainSkill = this.predefinedSkills.find((opt: any) => opt.url === this.$route.params.skill)
        if (mainSkill) {
          this.selectedCategories.push(mainSkill.id)
        }
      }
      this.selectedCategories = this.selectedCategories.concat((Array.isArray(skill) ? skill : [skill])
        .map((s: string) => +s)
        .filter(s => this.predefinedSkills.find((opt: any) => +opt.id === s))
        .filter(Boolean))
      this.selectedCategories = [...new Set(this.selectedCategories)]
      this.tempCategories = [...this.selectedCategories]
    },
    isSkillSelected (skill: Skill) {
      return Boolean(this.tempCategories.find((id: number) => id === skill.id))
    },
    onCategoryInput (skill: Skill) {
      const i = this.tempCategories.findIndex((s: string) => +s === skill.id)
      if (i !== -1) {
        this.tempCategories.splice(i, 1)
      } else {
        this.tempCategories.push(skill.id)
      }
    },
    onSkillsClear () {
      this.tempCategories = []
    },
    onSkillsApply () {
      this.selectedCategories = [...this.tempCategories]
      this.$refs?.skillRef?.closeMenu()
      this.mapToQuery()
    },
    onPriceInputClick (item: string) {
      this.priceInputOpened = {
        ...clearPriceInputOpened(),
        [item]: true,
      }
      this.$nextTick(() => {
        if (this.$refs[item]?.$el) {
          this.$refs[item].$el.querySelector('input')?.focus()
        }
      })
    },
    onClickAway (item: string) {
      if (this.priceInputOpened[item]) {
        this.priceInputOpened = {
          ...clearPriceInputOpened()
        }
      }
    },
    onShow (item: string) {
      this.menuOpened[item] = true
    },
    onHide (item: string) {
      this.menuOpened[item] = false
      if (item === 'price') {
        this.priceInputOpened = clearPriceInputOpened()
      } else if (item === 'skill') {
        this.tempCategories = [...this.selectedCategories]
      }
    },
    isFilterActive (filter: string) {
      return this.tags.some((tag: any) => tag.type === filter)
    },
    handleInputPrice (item: string, value: number) {
      let [from, to] = this.budget
      if (item === 'from') {
        from = value
      } else {
        to = value
      }
      if (from > MAX_BUDGET) {
        from = to = MAX_BUDGET
      }
      if (to > MAX_BUDGET) {
        to = MAX_BUDGET
      }
      if (from > to) {
        to = (from + 2) > MAX_BUDGET ? MAX_BUDGET : (from + 2)
      }
      this.budget = [from, to]
      this.debouncedMTQ()
    },
    onSliderChangeBudget (budget: Array<number>) {
      this.budget = budget
      this.debouncedMTQ()
    },
    onClickReset () {
      this.review = 0
      this.search = ''
      this.sortValue = SORT_OPTIONS[4]
      this.budget = [MIN_BUDGET, MAX_BUDGET]
      this.selectedCategories = []
      this.mapToQuery()
    },
    async onSortInput (sort: any) {
      if (sort && sort !== this.sortValue) {
        this.sortValue = sort
        this.mapToQuery()
      }
    },
    onTagDelete ({ tag }: { tag: any }) {
      switch (tag.type) {
        case 'sort': {
          this.sortValue = null
          break
        }
        case 'price': {
          this.budget = [MIN_BUDGET, MAX_BUDGET]
          break
        }
        default: {
          const skillIndex = this.selectedCategories.findIndex((s: string) => +tag.content.id === +s)
          if (skillIndex !== -1) {
            const selectedCategories = cloneDeep(this.selectedCategories)
            selectedCategories.splice(skillIndex, 1)
            this.selectedCategories = selectedCategories
          }
        }
      }
      this.mapToQuery()
    },
    onInputReview (review: string | number) {
      this.review = review
      this.mapToQuery()
    },
    onInputSearch: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    debouncedMTQ: debounce(function (this: any) {
      this.mapToQuery()
    }, 500),
    mapToQuery () {
      const filters = cloneDeep(pickBy({
        ...this.$route.query,
        search: this.search,
        sort: this.sortValue?.queryValue,
        budgetFrom: this.budget[0],
        budgetTo: this.budget[1],
        skill: this.selectedCategories,
        review: this.review,
      }, Boolean))
      let routeName = FREELANCERS_LIST
      let routeParams = cloneDeep(this.$route.params)
      if (this.$route.name === FREELANCERS_LIST_BY_SKILL) {
        if (this.selectedCategories.length) {
          routeName = FREELANCERS_LIST_BY_SKILL
        } else {
          routeParams = {}
        }
      }
      this.$router.replace({
        name: routeName,
        params: routeParams,
        query: filters,
      }).catch(() => {})
    },
  },
})
